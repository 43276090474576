import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/cefc_blog_header.png");
const section_1 = require("../../../assets/img/blogs/cefc_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/cefc_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/cefc_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/cefc_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/cefc_blog_img_5.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="ChatGPT Enterprise: Fact Check About Enterprise Deployment"
        description="Are custom workflow solutions and models easy to build and deploy with ChatGPT Enterprise? Amidst the hype, learn to know the challenges under the fold."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt cefc_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "text-align-center"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger-normal">
                    <span className="font-page-header-home-blog-bigger">
                      {" "}
                      ChatGPT Enterprise:
                    </span>{" "}
                    Fact Check About Enterpise Deployment
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger-normal">
                    <span className="font-page-header-home-blog-bigger">
                      {" "}
                      ChatGPT Enterprise:
                      <br />
                    </span>{" "}
                    Fact Check About Enterpise
                    <br /> Deployment
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. ChatGPT Enterprise: Faster, more secure, and more powerful
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What can you do with ChatGPT Enterprise?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Fact check: How easy is ChatGPT Enterprise to deploy?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Conversational AI x LLM - Use cases and benefits for
                  enterprises
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Workativ conversational AI x LLM - Why suitable for
                  enterprise Generative AI drive?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Was the Enterprise version of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai">
                  ChatGPT
                </a>{" "}
                on your watchlist? The wait is over now!
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Just after stirring a lot of imagination with its basic model as
                a chatbot interface or an advanced Plus model that provides
                better data analysis and Plugins for work, OpenAI brings to
                business leaders like you ChatGPT Enterprise with
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-security">
                  {" "}
                  enterprise-grade security and privacy features.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s the same AI-powered chatbot app but with more advanced
                performance capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So much to unleash for business-focused operations actually is
                put forward by Generative AI or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  Generative Pre-trained Transformer or large language models
                  (LLM)
                </a>{" "}
                underpinning ChatGPT types of models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In just eight months, Generative AI has captured a lot of
                attention, exhibiting promises to increase user productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The most apparent and common scene among businesses is the AI
                arms race now.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses want to adopt Generative AI essentially or, to some
                extent, to their enterprise workflows, make a change, and feel
                proud of being enthusiastic about the Generative AI wave.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If this has something in common with you, things be like this:
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You finally have the technology work for you. You are on a spree
                to announce that your organization is a visionary to adopt
                Generative AI and make an impact for everyone connected.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What is significant here is to have enough data to customize
                ChatGPT Enterprise specific to your unique business use cases
                and drive business values.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But that’s just one piece of the puzzle.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What does it take to scale up Generative AI tools like ChatGPT
                Enterprise to bring you all the benefits you aim for your
                business processes and drive massive user productivity?
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                ChatGPT Enterprise: Faster, more secure, and more powerful
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="ChatGPT Enterprise features"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                As the OpenAI's website claims, ChatGPT Enterprise can do a lot
                for business-focused functions for leaders with its unique
                features.
              </p>
              <h3 className="font-section-sub-header-small-home pl-4">
                1. 4x faster than the standard GPT-4:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                ChatGPT Enterprise gives consumers priority access to the GPT-4
                model with a 32k token context window.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                When you work with ChatGPT Enterprise, you can take advantage of
                a large context window, which performs twice as fast as the
                standard GPT-4 model to process 4X larger files or inputs.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-6">
                <li className="font-section-normal-text-testimonials">
                  Process large text inputs or files of about 25,000 words in
                  one go.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Establish contact with recent conversations and gain context
                  no matter the length.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Get zero wait times for information processing.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home pl-4">
                2. Model scalability:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                In the consumer version of ChatGPT, there is no way to add
                members in a bulk way and manage them.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Another concern is that the subscription is managed through
                emails, which needs to provide more flexibility to transfer the
                member access.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                ChatGPT Enterprise has a solution to the previous problem. It
                allows consumers to add bulk members to the platform through a
                dedicated admin console and easy single sign-on. Also, it
                provides domain verification and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/it-support-analytics-generative-ai">
                  dashboard analytics
                </a>{" "}
                to visualize user participation and usage.
              </p>
              <h3 className="font-section-sub-header-small-home pl-4">
                3. Enterprise-grade security:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                OpenAI clarifies that its Enterprise version does not use
                consumer data to train its model.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                All data communicated in the chat interface is encrypted in
                transit and at rest with AES-256 and TLS 1.2+ data. It means
                data passing through the internet won’t infringe the movement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                On top of it, ChatGPT Enterprise is SOC 2 compliant to ensure
                that it protects consumers' data while processing it and
                maintains privacy standards.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What can you do with ChatGPT Enterprise?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employees can quickly get the information they need to work. As
                per information available on the ChatGPT Enterprise website,
                there are multiple use cases where employees can benefit from
                this enterprise tool.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the use cases are similar to those of consumer versions
                or the Plus models, and the task can be done more efficiently,
                accurately, and securely.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You have longer texts, and you want a condensed version of it.
                ChatGPT Enterprise, using its 4x larger context window, can
                process it faster and give you the most appropriate summarized
                version of the texts best suited for marketing teams to write
                follow-up emails for their clients or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/virtual-agent-powered-it-helpdesk">
                  {" "}
                  IT desk agents
                </a>{" "}
                writing crisp responses for their users. Text summarization can
                be an efficient tool for any work that needs summarization for
                different purposes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Imagine you need to create original content, say, business
                proposals, employee contracts, new project guidelines, and so
                forth. In a fast-paced workplace, this isn’t easy at all.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But with ChatGPT Enterprise’s 32,000-token context window, you
                can produce any length of content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In relation to crafting a draft, ChatGPT Enterprise can be an
                easy help to make that information user-friendly. You can use
                the language translation feature to prepare a document in a
                specific language.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The language translation feature is also an excellent use case
                of ChatGPT Enterprise to help service desk agents work with
                foreign language-speaking people and provide a more flexible
                workplace for everyone.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your software engineering team is fast at writing codes and
                enables you to bring a solution faster.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What’s more!
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is a quicker project iteration with more rapid tests using
                ChatGPT Enterprise.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Suppose all of the above isn’t your core objective, and you want
                a chatbot service to help your internal people collaborate
                effectively and find a way to retrieve information and work. In
                that case, ChatGPT Enterprise can have vast potential.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Fact check: How easy is ChatGPT Enterprise to deploy?
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt=" ChatGPT Enterprise deployment hurdles "
              />

              <p className="font-section-normal-text-testimonials line-height-18">
                Things look so promising with all these vast possibilities
                lurking in the corner.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Isn’t it something that ChatGPT alone cannot bring you all those
                riches? Time is to consider some key factors.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s say you have a budget to invest in a ChatGPT Enterprise.
                But what’s next?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is essential to find out that ChatGPT surge's fantasies do
                not carry you out and explore the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk">
                  real benefits of your investment.
                </a>
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Give a deep thought to the state of cloud adoption. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Before ChatGPT became a sensation, IoT, Cloud, and even
                Blockchain made quite a stir. But, they are still in their
                nascency. ChatGPT Enterprise or any similar model or
                infrastructure needs robust cloud architecture to scale up and
                unleash the expected business results.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                No cloud infrastructure = no scale-up. Here’s why?
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                It’s essentially significant that to{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-automation">
                  {" "}
                  build your customized workflows,
                </a>{" "}
                you must have appropriate and enough data for your
                domain-specific work. There’s no denying about that.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                However, data gathering and storing needs a robust data
                structure for further processes like data analyzing and cleaning
                and integrating the data storage with the cloud and the
                Generative AI model.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Evaluate what’s your cloud readiness strategy. If you don’t have
                it yet, you can prioritize it. Well, that’s all at the expense
                of enormous costs.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>
                  Cloud platform migration is a strain on bottom-line expenses.{" "}
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Big players such as AWS, Google, Microsoft, and Nvidia are
                gaining enough transactions to deploy and scale Generative AI
                models.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                There are young players, too. But preferences always go with the
                known players rather than with someone about whom you have very
                little knowledge.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                No matter who you want to build a vendor relationship with, a
                new or established cloud deployment platform, vendor lock-in
                could become your primary challenge.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                A switch between an existing platform and something more robust
                or cost-effective like that of Databricks or Huggingface, be
                ready to pay for the data that dwells in the existing vendors’
                cloud platform.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Do you have the right AI team?</li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                What about the skilled talent? There is a talent shortage who
                can work with LLM and implement necessary functions to the
                infrastructure. Even if they are available, it is a pinch in
                your pocket.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                On top of it, ChatGPT Enterprise is an open-source platform,
                enabling you to customize its underlying infrastructure.
                However, working across ChatGPT Enterprise infrastructure is
                more challenging if one has limited{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                  {" "}
                  LLM or ChatGPT
                </a>{" "}
                environment knowledge. Unfortunately, the customization you need
                may be short of expectations.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                As you discover your business cases, you will soon find that
                customization could be more flexible with ChatGPT Enterprise.
                That’s another blow for you.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Calculate an initial state of costs associated—with model
                investment, capturing the right talent, and cloud infrastructure
                maintenance.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Legal implications</li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                When it is about data governance, it is challenging.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                ChatGPT says they provide Enterprise-grade data security,
                meaning you will be tempted to provide personal data inside the
                infrastructure, assuming the architecture will not learn from
                users' inputs and train the algorithms.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                However, global data regulators expressed concerns over
                Generative AI usage and the collection of users' data.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The European Union and the Federal Trade Commission are
                considering ChatGPT's commercial use concerning the probability
                of the violation of data privacy and public safety, as well as
                the increase of bias.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                In a similar context of exposing public data to the ChatGPT
                platform, Italy's regulatory agency, Garante, banned it, citing
                that{" "}
                <a href="https://www.cnbc.com/2023/04/04/italy-has-banned-chatgpt-heres-what-other-countries-are-doing.html">
                  OpenAI exposes public data
                </a>{" "}
                and allows other users to view the chatbot's conversation
                titles.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Italy also put a fine of 20 million euros or 4% of its global
                revenue later, though Italy lifted a ban.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Other EU countries, such as Brussels, Ireland, and European
                countries, including France and Germany, showed interest in
                discovering more about ChatGPT risks using Italy's research work
                and followed suit.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Regulators believe that AI is here to simplify our jobs and not
                take control of human intelligence.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Sophie Hackford, an advisor at John Deere, said, "We need to be
                thinking about it very carefully now, and we need to be acting
                on that now, from a regulation perspective."
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Based on all of these perspectives, if AI is unregulated, it can
                raise some risks for data governance and inflict your business
                reputation if any violation happens on the legal front.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Legal implications may be more unsatisfactory in the future.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                AI models tend to self-learn on AI-generated internet content,
                overlapping the training corpus built with human-generated
                content. So, models learn from wrong information and generate
                information to cause workplace discrimination, such as bias or
                exposing private data to third-party service providers, to
                further increase the risk of cybersecurity threats.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The penalty for a data breach is not hidden from anyone.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                If you risk it, you can foresee the consequences well.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conversational AI x LLM - Use cases and benefits for enterprises
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Let’s say you do not have any essential investment budgets for
                your enterprise workflows. Does it mean your Generative AI
                ambitions will not soar?
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Enterprise leaders look to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/increase-employee-productivity">
                  maximize the productivity of their employees
                </a>{" "}
                in the best possible way to unleash maximum benefits on the
                service delivery, customer experience, and growth side. What
                essentially do you need to do to attain these ambitions?
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Conversational AI, in combination with a large language model,
                </a>
                is the answer to your quest to join the Generative AI race and
                drive real business values.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                A workplace conversational AI platform is significant enough to
                facilitate many low-priority work in a much more streamlined and
                automated manner. We refer to them as low-priority work. The
                fact, however, is that they consume the essential time of the
                service desk agents and prevent them from addressing more
                critical requests across all business functions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say a user constantly faces a sync issue between his desktop
                system and cloud infrastructure; it is a massive productivity
                constraint for any business and a critical issue that seeks
                immediate help.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                However, if agents are engrossed with common requests, they need
                more time for the critical issues.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To gain the power of large language models, you can get hold of
                a conversational AI platform, which is an efficient tool for
                applying Generative AI features to knowledge discovery and
                enabling them to retrieve the correct information.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                So, when your employees have accurate and contextual
                information, they are fast at their work.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Generative AI and conversational AI, when combined, ramp up the
                self-service capability to a few notches high.
              </p>
              <h3 className="font-section-sub-header-small-home">Use cases:</h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="use cases of conversational AI and LLM for workplace support "
              />
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Automate and streamline employee tasks. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Everyday tasks such as VPN settings, password resets, account
                unlocks, and user provisions are familiar to the IT service
                desk.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                With automated app{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  workflows built upon knowledge base FAQs or determined dialog
                  flows,
                </a>{" "}
                your employees can instantly get help and solve a problem.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Facilitate more critical tasks. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                A live agent handover can easily address printer issues
                unresolved by tier 0 or tier 1. An agent readily understands the
                issue and provides help with past and present conversation
                history. As a result, employee productivity increases through
                instant and real-time responses.
              </p>
              <BlogCta
                ContentCta="Automate your enterprise employee support with Genegrative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Provide work updates instantly. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                For a particular department, say a DevOps team handling a cloud
                migration project, if a manager is unavailable in the middle of
                the project, a conversational AI can be effective enough to help
                anyone associated with the assignment get the latest updates on
                the project progress and move ahead.
              </p>

              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Deliver non-IT support </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The effectiveness of integrating large language models with
                conversational AI facilitates non-IT task management. For
                example, you can quickly redefine HR support, such as employee
                onboarding and PTO inquiry.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Say an employee wants to query about leave balances; a
                conversational AI with LLM embedded inside it can deliver only a
                straightforward answer.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Employees no longer need to work with links to the HRMS to
                navigate through a list of holidays taken and remaining
                balances. They can see a figure that displays the number of
                leaves remaining.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Derive advanced data analytics. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Generative AI and conversational AI platforms fetch more
                real-time data across bot activity, including issues resolved
                through self-serve, tickets handled by agents, and a number of
                unresolved tickets, etc. The advanced data is efficient in
                making improvements to the conversation flows and addressing
                more issues seamlessly.
              </p>
              <h3 className="font-section-sub-header-small-home">Benefits:</h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Benefits of conversational AI and LLM for workplace support "
              />
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Proactive service desk support </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Conversational AI with the power of LLM can help you achieve the
                proactive service desk status. You can fetch data to find root
                cause analysis and efficiently solve the repetitive problem.
              </p>

              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Increase in user productivity </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The flexibility of knowledge discovery, enabling more efficient
                self-service, boosts employee efficiency and productivity for
                the internal workforce, including service desk agents.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>User experience </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Employees can solve their usual productivity problems at scale.
                There are fewer frictions in getting the correct information to
                solve day-to-day workplace issues and resume work more steadily.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Cost efficiency </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                LLM-powered conversational AI solves problems more efficiently,
                meaning enterprises can have fewer backlogs of service desk
                requests, which reduces agent utilization and consequently
                reduces costs for bottom-line expenses.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ conversational AI x LLM - Why suitable for enterprise
                Generative AI drive?
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" a combination of both worlds - conversational AI and LLM within Workativ for workplace support automation                 "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Workativ conversational AI embeds the large language model
                  capability
                </a>{" "}
                inside its no-code chatbot platform, which is flexible and
                convenient for employees to solve workplace problems
                efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workative simplifies the knowledge search using Generative AI
                properties and helps your employees find information at scale to
                solve low-priority issues efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                When you have Workativ, you can have the power of conversational
                AI and LLM together, allowing you to apply use cases specific to
                your business needs and gain all the benefits as stated in the
                above section.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                So, how does Workativ make it easy for you to drive your
                Generative AI initiative without all the cost required to build
                custom solutions with ChatGPT Enterprise?
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Ease of customization </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Workativ is a no-code conversational AI platform. Setting up
                your chatbot with LLM integrations is relatively easy for
                everyone in your organization. It does not require a steep
                learning curve of exceptional abilities with coding, which is a
                must for customization with the ChatGPT Enterprise ecosystem.
                Using our help doc, you can achieve easy deployment.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>No developer cost involved </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Since ours is a no-code platform, you have no liabilities to
                hire a specialist with niches around AI. It is easy for any
                non-technical person to configure dialog flows inside the
                chatbot, test, and push live.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Zero total cost of ownership </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                As with a custom solution, you aim to build with ChatGPT
                Enterprise, you must take care of TCO. As discussed earlier, the
                costs involve the charges for cloud platforms, Generative AI
                model, data storage, and search UI. Since it is a
                subscription-based model, you must bear the recurring costs as
                long as you use these services.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                In contrast with the burden of TCO, our SaaS-based platform,
                Workativ, offers a straightforward pricing model. So, pay for
                what you use.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                No cost is included for DB, LLM models, computational platforms,
                etc.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Generative AI security </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Workativ provides Enterprise-level security and compliance
                requirements, including GDPR, HIPAA, and industry-specific
                standards.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                With that, security does not rest upon you. Workativ ensures the
                responsible use of LLMs without the risk of exposing your data
                to the infrastructure and causing any security breaches.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                However, checking your training data to eliminate the risk of
                exposure to PII information or any confidential company data is
                always desirable.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                ChatGPT Enterprise indeed holds a lot of promises for
                enterprises to achieve their ambitious business goals.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses of many types have expressed keen interest in
                applying Generative AI abilities to their various business
                functions. Many leading organizations across healthcare, food
                and beverages, life sciences, and media houses have built their
                solutions and use them in their unique use cases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                So far, so good. But can these businesses drive absolute
                business values with their investment? This is something hidden.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                So, if you set high expectations for Generative AI or ChatGPT
                Enterprise, consider the above facts. It is a challenging
                iteration. It is time-consuming and may take years to leverage
                the real value for your money.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                For a quick start with your Generative AI project, though,
                Workativ can be transformative and offer great workplace
                transformation opportunities.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To know more about your scope for enterprise business use cases,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. ChatGPT Enterprise: Faster, more secure, and more
                    powerful
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What can you do with ChatGPT Enterprise?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Fact check: How easy is ChatGPT Enterprise to deploy?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Conversational AI x LLM - Use cases and benefits for
                    enterprises
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Workativ conversational AI x LLM - Why suitable for
                    enterprise Generative AI drive?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Was the Enterprise version of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai">
                  ChatGPT
                </a>{" "}
                on your watchlist? The wait is over now!
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Just after stirring a lot of imagination with its basic model as
                a chatbot interface or an advanced Plus model that provides
                better data analysis and Plugins for work, OpenAI brings to
                business leaders like you ChatGPT Enterprise with
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-security">
                  {" "}
                  enterprise-grade security and privacy features.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It’s the same AI-powered chatbot app but with more advanced
                performance capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So much to unleash for business-focused operations actually is
                put forward by Generative AI or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  Generative Pre-trained Transformer or large language models
                  (LLM)
                </a>{" "}
                underpinning ChatGPT types of models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In just eight months, Generative AI has captured a lot of
                attention, exhibiting promises to increase user productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The most apparent and common scene among businesses is the AI
                arms race now.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses want to adopt Generative AI essentially or, to some
                extent, to their enterprise workflows, make a change, and feel
                proud of being enthusiastic about the Generative AI wave.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If this has something in common with you, things be like this:
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You finally have the technology work for you. You are on a spree
                to announce that your organization is a visionary to adopt
                Generative AI and make an impact for everyone connected.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What is significant here is to have enough data to customize
                ChatGPT Enterprise specific to your unique business use cases
                and drive business values.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But that’s just one piece of the puzzle.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What does it take to scale up Generative AI tools like ChatGPT
                Enterprise to bring you all the benefits you aim for your
                business processes and drive massive user productivity?
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                ChatGPT Enterprise: Faster, more secure, and more powerful
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="ChatGPT Enterprise features"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                As the OpenAI's website claims, ChatGPT Enterprise can do a lot
                for business-focused functions for leaders with its unique
                features.
              </p>
              <h3 className="font-section-sub-header-small-home pl-4">
                1. 4x faster than the standard GPT-4:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                ChatGPT Enterprise gives consumers priority access to the GPT-4
                model with a 32k token context window.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                When you work with ChatGPT Enterprise, you can take advantage of
                a large context window, which performs twice as fast as the
                standard GPT-4 model to process 4X larger files or inputs.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-6">
                <li className="font-section-normal-text-testimonials">
                  Process large text inputs or files of about 25,000 words in
                  one go.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Establish contact with recent conversations and gain context
                  no matter the length.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Get zero wait times for information processing.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home pl-4">
                2. Model scalability:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                In the consumer version of ChatGPT, there is no way to add
                members in a bulk way and manage them.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Another concern is that the subscription is managed through
                emails, which needs to provide more flexibility to transfer the
                member access.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                ChatGPT Enterprise has a solution to the previous problem. It
                allows consumers to add bulk members to the platform through a
                dedicated admin console and easy single sign-on. Also, it
                provides domain verification and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/it-support-analytics-generative-ai">
                  dashboard analytics
                </a>{" "}
                to visualize user participation and usage.
              </p>
              <h3 className="font-section-sub-header-small-home pl-4">
                3. Enterprise-grade security:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                OpenAI clarifies that its Enterprise version does not use
                consumer data to train its model.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                All data communicated in the chat interface is encrypted in
                transit and at rest with AES-256 and TLS 1.2+ data. It means
                data passing through the internet won’t infringe the movement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                On top of it, ChatGPT Enterprise is SOC 2 compliant to ensure
                that it protects consumers' data while processing it and
                maintains privacy standards.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What can you do with ChatGPT Enterprise?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employees can quickly get the information they need to work. As
                per information available on the ChatGPT Enterprise website,
                there are multiple use cases where employees can benefit from
                this enterprise tool.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the use cases are similar to those of consumer versions
                or the Plus models, and the task can be done more efficiently,
                accurately, and securely.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                You have longer texts, and you want a condensed version of it.
                ChatGPT Enterprise, using its 4x larger context window, can
                process it faster and give you the most appropriate summarized
                version of the texts best suited for marketing teams to write
                follow-up emails for their clients or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/virtual-agent-powered-it-helpdesk">
                  {" "}
                  IT desk agents
                </a>{" "}
                writing crisp responses for their users. Text summarization can
                be an efficient tool for any work that needs summarization for
                different purposes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Imagine you need to create original content, say, business
                proposals, employee contracts, new project guidelines, and so
                forth. In a fast-paced workplace, this isn’t easy at all.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But with ChatGPT Enterprise’s 32,000-token context window, you
                can produce any length of content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In relation to crafting a draft, ChatGPT Enterprise can be an
                easy help to make that information user-friendly. You can use
                the language translation feature to prepare a document in a
                specific language.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The language translation feature is also an excellent use case
                of ChatGPT Enterprise to help service desk agents work with
                foreign language-speaking people and provide a more flexible
                workplace for everyone.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your software engineering team is fast at writing codes and
                enables you to bring a solution faster.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What’s more!
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is a quicker project iteration with more rapid tests using
                ChatGPT Enterprise.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Suppose all of the above isn’t your core objective, and you want
                a chatbot service to help your internal people collaborate
                effectively and find a way to retrieve information and work. In
                that case, ChatGPT Enterprise can have vast potential.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Fact check: How easy is ChatGPT Enterprise to deploy?
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt=" ChatGPT Enterprise deployment hurdles "
              />

              <p className="font-section-normal-text-testimonials line-height-18">
                Things look so promising with all these vast possibilities
                lurking in the corner.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Isn’t it something that ChatGPT alone cannot bring you all those
                riches? Time is to consider some key factors.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s say you have a budget to invest in a ChatGPT Enterprise.
                But what’s next?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is essential to find out that ChatGPT surge's fantasies do
                not carry you out and explore the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk">
                  real benefits of your investment.
                </a>
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Give a deep thought to the state of cloud adoption. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Before ChatGPT became a sensation, IoT, Cloud, and even
                Blockchain made quite a stir. But, they are still in their
                nascency. ChatGPT Enterprise or any similar model or
                infrastructure needs robust cloud architecture to scale up and
                unleash the expected business results.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                No cloud infrastructure = no scale-up. Here’s why?
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                It’s essentially significant that to{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-automation">
                  {" "}
                  build your customized workflows,
                </a>{" "}
                you must have appropriate and enough data for your
                domain-specific work. There’s no denying about that.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                However, data gathering and storing needs a robust data
                structure for further processes like data analyzing and cleaning
                and integrating the data storage with the cloud and the
                Generative AI model.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Evaluate what’s your cloud readiness strategy. If you don’t have
                it yet, you can prioritize it. Well, that’s all at the expense
                of enormous costs.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>
                  Cloud platform migration is a strain on bottom-line expenses.{" "}
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Big players such as AWS, Google, Microsoft, and Nvidia are
                gaining enough transactions to deploy and scale Generative AI
                models.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                There are young players, too. But preferences always go with the
                known players rather than with someone about whom you have very
                little knowledge.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                No matter who you want to build a vendor relationship with, a
                new or established cloud deployment platform, vendor lock-in
                could become your primary challenge.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                A switch between an existing platform and something more robust
                or cost-effective like that of Databricks or Huggingface, be
                ready to pay for the data that dwells in the existing vendors’
                cloud platform.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Do you have the right AI team?</li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                What about the skilled talent? There is a talent shortage who
                can work with LLM and implement necessary functions to the
                infrastructure. Even if they are available, it is a pinch in
                your pocket.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                On top of it, ChatGPT Enterprise is an open-source platform,
                enabling you to customize its underlying infrastructure.
                However, working across ChatGPT Enterprise infrastructure is
                more challenging if one has limited{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                  {" "}
                  LLM or ChatGPT
                </a>{" "}
                environment knowledge. Unfortunately, the customization you need
                may be short of expectations.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                As you discover your business cases, you will soon find that
                customization could be more flexible with ChatGPT Enterprise.
                That’s another blow for you.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Calculate an initial state of costs associated—with model
                investment, capturing the right talent, and cloud infrastructure
                maintenance.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Legal implications</li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                When it is about data governance, it is challenging.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                ChatGPT says they provide Enterprise-grade data security,
                meaning you will be tempted to provide personal data inside the
                infrastructure, assuming the architecture will not learn from
                users' inputs and train the algorithms.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                However, global data regulators expressed concerns over
                Generative AI usage and the collection of users' data.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The European Union and the Federal Trade Commission are
                considering ChatGPT's commercial use concerning the probability
                of the violation of data privacy and public safety, as well as
                the increase of bias.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                In a similar context of exposing public data to the ChatGPT
                platform, Italy's regulatory agency, Garante, banned it, citing
                that{" "}
                <a href="https://www.cnbc.com/2023/04/04/italy-has-banned-chatgpt-heres-what-other-countries-are-doing.html">
                  OpenAI exposes public data
                </a>{" "}
                and allows other users to view the chatbot's conversation
                titles.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Italy also put a fine of 20 million euros or 4% of its global
                revenue later, though Italy lifted a ban.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Other EU countries, such as Brussels, Ireland, and European
                countries, including France and Germany, showed interest in
                discovering more about ChatGPT risks using Italy's research work
                and followed suit.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Regulators believe that AI is here to simplify our jobs and not
                take control of human intelligence.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Sophie Hackford, an advisor at John Deere, said, "We need to be
                thinking about it very carefully now, and we need to be acting
                on that now, from a regulation perspective."
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Based on all of these perspectives, if AI is unregulated, it can
                raise some risks for data governance and inflict your business
                reputation if any violation happens on the legal front.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Legal implications may be more unsatisfactory in the future.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                AI models tend to self-learn on AI-generated internet content,
                overlapping the training corpus built with human-generated
                content. So, models learn from wrong information and generate
                information to cause workplace discrimination, such as bias or
                exposing private data to third-party service providers, to
                further increase the risk of cybersecurity threats.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The penalty for a data breach is not hidden from anyone.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                If you risk it, you can foresee the consequences well.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conversational AI x LLM - Use cases and benefits for enterprises
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Let’s say you do not have any essential investment budgets for
                your enterprise workflows. Does it mean your Generative AI
                ambitions will not soar?
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Enterprise leaders look to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/increase-employee-productivity">
                  maximize the productivity of their employees
                </a>{" "}
                of their employees in the best possible way to unleash maximum
                benefits on the service delivery, customer experience, and
                growth side. What essentially do you need to do to attain these
                ambitions?
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Conversational AI, in combination with a large language model,
                </a>
                is the answer to your quest to join the Generative AI race and
                drive real business values.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                A workplace conversational AI platform is significant enough to
                facilitate many low-priority work in a much more streamlined and
                automated manner. We refer to them as low-priority work. The
                fact, however, is that they consume the essential time of the
                service desk agents and prevent them from addressing more
                critical requests across all business functions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say a user constantly faces a sync issue between his desktop
                system and cloud infrastructure; it is a massive productivity
                constraint for any business and a critical issue that seeks
                immediate help.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                However, if agents are engrossed with common requests, they need
                more time for the critical issues.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To gain the power of large language models, you can get hold of
                a conversational AI platform, which is an efficient tool for
                applying Generative AI features to knowledge discovery and
                enabling them to retrieve the correct information.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                So, when your employees have accurate and contextual
                information, they are fast at their work.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Generative AI and conversational AI, when combined, ramp up the
                self-service capability to a few notches high.
              </p>
              <h3 className="font-section-sub-header-small-home">Use cases:</h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="use cases of conversational AI and LLM for workplace support "
              />
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Automate and streamline employee tasks. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Everyday tasks such as VPN settings, password resets, account
                unlocks, and user provisions are familiar to the IT service
                desk.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                With automated app{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  workflows built upon knowledge base FAQs or determined dialog
                  flows,
                </a>{" "}
                your employees can instantly get help and solve a problem.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Facilitate more critical tasks. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                A live agent handover can easily address printer issues
                unresolved by tier 0 or tier 1. An agent readily understands the
                issue and provides help with past and present conversation
                history. As a result, employee productivity increases through
                instant and real-time responses.
              </p>
              <BlogCta
                ContentCta="Automate your enterprise employee support with Genegrative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Provide work updates instantly. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                For a particular department, say a DevOps team handling a cloud
                migration project, if a manager is unavailable in the middle of
                the project, a conversational AI can be effective enough to help
                anyone associated with the assignment get the latest updates on
                the project progress and move ahead.
              </p>

              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Deliver non-IT support </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The effectiveness of integrating large language models with
                conversational AI facilitates non-IT task management. For
                example, you can quickly redefine HR support, such as employee
                onboarding and PTO inquiry.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Say an employee wants to query about leave balances; a
                conversational AI with LLM embedded inside it can deliver only a
                straightforward answer.
              </p>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Employees no longer need to work with links to the HRMS to
                navigate through a list of holidays taken and remaining
                balances. They can see a figure that displays the number of
                leaves remaining.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Derive advanced data analytics. </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Generative AI and conversational AI platforms fetch more
                real-time data across bot activity, including issues resolved
                through self-serve, tickets handled by agents, and a number of
                unresolved tickets, etc. The advanced data is efficient in
                making improvements to the conversation flows and addressing
                more issues seamlessly.
              </p>
              <h3 className="font-section-sub-header-small-home">Benefits:</h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Benefits of conversational AI and LLM for workplace support "
              />
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Proactive service desk support </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Conversational AI with the power of LLM can help you achieve the
                proactive service desk status. You can fetch data to find root
                cause analysis and efficiently solve the repetitive problem.
              </p>

              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Increase in user productivity </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                The flexibility of knowledge discovery, enabling more efficient
                self-service, boosts employee efficiency and productivity for
                the internal workforce, including service desk agents.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>User experience </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Employees can solve their usual productivity problems at scale.
                There are fewer frictions in getting the correct information to
                solve day-to-day workplace issues and resume work more steadily.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Cost efficiency </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                LLM-powered conversational AI solves problems more efficiently,
                meaning enterprises can have fewer backlogs of service desk
                requests, which reduces agent utilization and consequently
                reduces costs for bottom-line expenses.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ conversational AI x LLM - Why suitable for enterprise
                Generative AI drive?
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" a combination of both worlds - conversational AI and LLM within Workativ for workplace support automation                 "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Workativ conversational AI embeds the large language model
                  capability
                </a>{" "}
                inside its no-code chatbot platform, which is flexible and
                convenient for employees to solve workplace problems
                efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workative simplifies the knowledge search using Generative AI
                properties and helps your employees find information at scale to
                solve low-priority issues efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                When you have Workativ, you can have the power of conversational
                AI and LLM together, allowing you to apply use cases specific to
                your business needs and gain all the benefits as stated in the
                above section.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                So, how does Workativ make it easy for you to drive your
                Generative AI initiative without all the cost required to build
                custom solutions with ChatGPT Enterprise?
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Ease of customization </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18 ">
                Workativ is a no-code conversational AI platform. Setting up
                your chatbot with LLM integrations is relatively easy for
                everyone in your organization. It does not require a steep
                learning curve of exceptional abilities with coding, which is a
                must for customization with the ChatGPT Enterprise ecosystem.
                Using our help doc, you can achieve easy deployment.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>No developer cost involved </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Since ours is a no-code platform, you have no liabilities to
                hire a specialist with niches around AI. It is easy for any
                non-technical person to configure dialog flows inside the
                chatbot, test, and push live.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Zero total cost of ownership </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                As with a custom solution, you aim to build with ChatGPT
                Enterprise, you must take care of TCO. As discussed earlier, the
                costs involve the charges for cloud platforms, Generative AI
                model, data storage, and search UI. Since it is a
                subscription-based model, you must bear the recurring costs as
                long as you use these services.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                In contrast with the burden of TCO, our SaaS-based platform,
                Workativ, offers a straightforward pricing model. So, pay for
                what you use.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                No cost is included for DB, LLM models, computational platforms,
                etc.
              </p>
              <ul className="float-left w-100 mb-0 pl-4 font-section-sub-header-small-home">
                <li>Generative AI security </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Workativ provides Enterprise-level security and compliance
                requirements, including GDPR, HIPAA, and industry-specific
                standards.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                With that, security does not rest upon you. Workativ ensures the
                responsible use of LLMs without the risk of exposing your data
                to the infrastructure and causing any security breaches.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                However, checking your training data to eliminate the risk of
                exposure to PII information or any confidential company data is
                always desirable.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                ChatGPT Enterprise indeed holds a lot of promises for
                enterprises to achieve their ambitious business goals.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses of many types have expressed keen interest in
                applying Generative AI abilities to their various business
                functions. Many leading organizations across healthcare, food
                and beverages, life sciences, and media houses have built their
                solutions and use them in their unique use cases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                So far, so good. But can these businesses drive absolute
                business values with their investment? This is something hidden.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                So, if you set high expectations for Generative AI or ChatGPT
                Enterprise, consider the above facts. It is a challenging
                iteration. It is time-consuming and may take years to leverage
                the real value for your money.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                For a quick start with your Generative AI project, though,
                Workativ can be transformative and offer great workplace
                transformation opportunities.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To know more about your scope for enterprise business use cases,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              Conversational AI: The Next Frontier in Enterprise Collaboration
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies"
              className="font-section-normal-text-testimonials"
            >
              Generative AI for Dummies ( what, how, why for companies)
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
